import { Link } from "gatsby"
import React from "react"

import { AiFillCaretDown } from "react-icons/ai"
import { UncontrolledCollapse } from "reactstrap"

const Menu = () => {
  return (
    <>
      <div className="row flex-column">
        <div>
          <div
            className="toggle-btn d-flex align-items-center"
            onclick="void(0)"
            aria-label="services"
            id="services-menu"
          >
            <Link aria-label="link" to="/">
              Services
            </Link>
            <AiFillCaretDown />
          </div>

          <UncontrolledCollapse toggler="#services-menu">
            <div className="d-flex flex-column collapse-content">
              <h5 className="sidebar-subtitle">Innovation</h5>
              <Link aria-label="link" to="/innovation-analysis/">
                Innovation Analysis
              </Link>
              <Link aria-label="link" to="/rapid-innovation/">
                Rapid Innovation
              </Link>
              <Link aria-label="link" to="/software-asset-management/">
                Software Asset Management
              </Link>
              <Link aria-label="link" to="/operationalize-ai/">
                Operationalize AI
              </Link>

              <h5 className="sidebar-subtitle">Quickbase Services</h5>
              <Link
                aria-label="link"
                to="/custom-quickbase-application-development/"
              >
                Application Development
              </Link>
              <Link aria-label="link" to="/quickbase-integration-services/">
                Integration Services
              </Link>
              <Link aria-label="link" to="/application-security-audit/">
                Application Security Audit
              </Link>
              <Link aria-label="link" to="/application-health-audit/">
                Application Health Audit
              </Link>
              <Link aria-label="link" to="/post-launch-enablement-services/">
                Post Launch Enablement
              </Link>
              <Link
                aria-label="link"
                className="dropdown-text"
                to="/peaksuite/"
              >
                peakSUITE
              </Link>
              {/* <div className="d-flex flex-column collapse-content">
                <div className="d-flex align-items-center">
                  <Link aria-label="link" to="/peaksuite/">
                    peaksuite
                  </Link>
                  <AiFillCaretDown id="products-peaksuite" />
                </div>
                <UncontrolledCollapse
                  toggler="#products-peaksuite"
                  style={{ marginLeft: "1rem" }}
                >
                  <div className="d-flex flex-column">
                    <Link aria-label="link" to="/peaksuite/peaksuite-compass/">
                      compass
                    </Link>

                    <Link
                      aria-label="link"
                      to="/peaksuite/peaksuite-docgenerator/"
                    >
                      document generator
                    </Link>

                    <Link
                      aria-label="link"
                      to="/peaksuite/peaksuite-multi-file-uploader/"
                    >
                      Multi-File Uploader
                    </Link>
                  </div>
                </UncontrolledCollapse>
              </div> */}

              {/* <Link aria-label="link" to="/tech-consulting/">
                Technology Consulting
              </Link>
              <Link aria-label="link" to="/procurement-automation-services/">
                Procurement Automation
              <Link
                aria-label="link"
                to="/custom-quickbase-application-development/"
              >
                Application Development
              </Link>
              <Link aria-label="link" to="/citizen-development-support/">
                Citizen Development
              </Link> */}
              {/* <h5 className="sidebar-subtitle">Industry</h5>
              <Link
                aria-label="link"
                to="/offline-application-development-construction-management/"
              >
                Construction
              </Link>
              <Link aria-label="link" to="/financial-services-automation/">
                Finance
              </Link>
              <Link aria-label="link" to="/patient-care-automation/">
                Healthcare
              </Link>
              <Link aria-label="link" to="/retail-automation/">
                Retail
              </Link>
              <Link aria-label="link" to="/transportation-and-logistics/">
                Transportation
              </Link> */}
            </div>
          </UncontrolledCollapse>
        </div>
        <div>
          <div
            className="toggle-btn d-flex align-items-center mt-3"
            id="products-menu"
          >
            <Link aria-label="link" to="/">
              Partners
            </Link>
            <AiFillCaretDown />
          </div>
          <UncontrolledCollapse
            // isOpen={isOpen === "products"}
            toggler="#products-menu"
          >
            <div className="d-flex flex-column collapse-content">
              {/* <div className="d-flex align-items-center">
                <Link aria-label="link" to="/peaksuite/">
                  peaksuite
                </Link>
                <AiFillCaretDown id="products-peaksuite" />
              </div>
              <UncontrolledCollapse
                toggler="#products-peaksuite"
                style={{ marginLeft: "1rem" }}
              >
                <div className="d-flex flex-column">
                  <Link aria-label="link" to="/peaksuite/peaksuite-compass/">
                    compass
                  </Link>

                  <Link
                    aria-label="link"
                    to="/peaksuite/peaksuite-docgenerator/"
                  >
                    document generator
                  </Link>

                  <Link
                    aria-label="link"
                    to="/peaksuite/peaksuite-multi-file-uploader/"
                  >
                    Multi-File Uploader
                  </Link>
                </div>
              </UncontrolledCollapse> */}
              <Link aria-label="link" to="/partners/quickbase/">
                Quickbase
              </Link>
              <Link aria-label="link" to="/partners/workato/">
                Workato
              </Link>
              <Link aria-label="link" to="/partners/pipefy/">
                Pipefy
              </Link>
              <a aria-label="link" href="/partners/trackvia/">
                TrackVia
              </a>
            </div>
          </UncontrolledCollapse>
        </div>
        <Link aria-label="link" to="/case-studies/" className="mt-3">
          case studies
        </Link>
        <div>
          <div
            className="toggle-btn d-flex align-items-center mt-3"
            id="resources"
          >
            <Link aria-label="link" to="/">
              resources
            </Link>
            <AiFillCaretDown />
          </div>
          <UncontrolledCollapse toggler="#resources">
            <div className="d-flex flex-column collapse-content">
              <Link aria-label="link" to="/knowledge-base/">
                knowledge base
              </Link>
              <Link aria-label="link" to="/blog/">
                Blog
              </Link>
              <Link aria-label="link" to="/faq/">
                faq
              </Link>
            </div>
          </UncontrolledCollapse>
        </div>
        <div>
          <div
            className="toggle-btn d-flex align-items-center mt-3"
            id="who-are-we-menu"
          >
            <Link aria-label="link" to="/">
              About
            </Link>
            <AiFillCaretDown />
          </div>
          <UncontrolledCollapse toggler="#who-are-we-menu">
            <div className="d-flex flex-column collapse-content">
              <Link aria-label="link" to="/about-us/">
                about us
              </Link>
              <Link aria-label="link" to="/our-approach/">
                our approach
              </Link>
              <Link aria-label="link" to="/careers/">
                careers
              </Link>
            </div>
          </UncontrolledCollapse>
        </div>
      </div>
    </>
  )
}

export default Menu
