import React from "react"
import { Link } from "gatsby"
import Search from "../search/search"
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"

import { DropdownButton, Dropdown, ButtonGroup } from "react-bootstrap"

const HorizontalMenu = ({ dataSrc }) => {
  const caseStudyItem = dataSrc?.edges[0]

  return (
    <>
      <div className="hmenu-wrapper">
        <div className="container d-container px-0">
          <div className="d-flex justify-content-between align-items-center">
            {/* Links */}
            <div style={{ display: "flex", alignItems: "center" }}>
              <nav>
                <ul className="">
                  <li className="dropdown">
                    <a href="#" className="nav-text d-flex align-items-center">
                      Services <i className="fa fa-caret-down ml-1"></i>
                    </a>
                    <div className="mega-menu">
                      <div className="container">
                        <div className="item">
                          <h4>Innovation</h4>
                          <ul>
                            <li>
                              <Link
                                aria-label="link"
                                className="dropdown-text"
                                to="/innovation-analysis/"
                              >
                                Innovation Analysis
                              </Link>
                            </li>
                            <li>
                              <Link
                                aria-label="link"
                                className="dropdown-text"
                                to="/rapid-innovation/"
                              >
                                Rapid Innovation
                              </Link>
                            </li>
                            <li>
                              <Link
                                aria-label="link"
                                className="dropdown-text"
                                to="/software-asset-management/"
                              >
                                Software Asset Management
                              </Link>
                            </li>
                            <li>
                              <Link
                                aria-label="link"
                                className="dropdown-text"
                                to="/operationalize-ai/"
                              >
                                Operationalize AI
                              </Link>
                            </li>
                          </ul>
                        </div>

                        <div className="item">
                          <h4>Quickbase Services</h4>
                          <ul>
                            <li>
                              {" "}
                              <Link
                                aria-label="link"
                                className="dropdown-text"
                                to="/custom-quickbase-application-development/"
                              >
                                Application Development
                              </Link>
                            </li>
                            <li>
                              {" "}
                              <Link
                                aria-label="link"
                                className="dropdown-text"
                                to="/quickbase-integration-services/"
                              >
                                Integration Services
                              </Link>
                            </li>
                            <li>
                              {" "}
                              <Link
                                aria-label="link"
                                className="dropdown-text"
                                to="/application-security-audit/"
                              >
                                Application Security Audit
                              </Link>
                            </li>
                            <li>
                              {" "}
                              <Link
                                aria-label="link"
                                className="dropdown-text"
                                to="/application-health-audit/"
                              >
                                Application Health Audit
                              </Link>
                            </li>
                            <li>
                              <Link
                                aria-label="link"
                                className="dropdown-text"
                                to="/post-launch-enablement-services/"
                              >
                                Post Launch Enablement
                              </Link>
                            </li>
                            <li>
                              {" "}
                              <Link
                                aria-label="link"
                                className="dropdown-text"
                                to="/peaksuite/"
                              >
                                peakSUITE
                              </Link>
                            </li>

                            {/* <li>
                            {" "}
                            <Link
                              aria-label="link"
                              className="dropdown-text"
                              to="/citizen-development-support/"
                            >
                              Citizen Development
                            </Link>
                          </li>
                          <li>
                            {" "}
                            <Link
                              aria-label="link"
                              className="dropdown-text"
                              to="/tech-consulting/"
                            >
                              Technology Consulting
                            </Link>
                          </li>

                          <li>
                            <Link
                              aria-label="link"
                              className="dropdown-text"
                              to="/procurement-automation-services/"
                            >
                              Procurement Automation
                            </Link>
                          </li> */}
                          </ul>
                        </div>
                        {/* <div className="item">
                        <h4>Industry</h4>
                        <ul>
                          <li>
                            {" "}
                            <Link
                              aria-label="link"
                              className="dropdown-text"
                              to="/offline-application-development-construction-management/"
                            >
                              Construction
                            </Link>
                          </li>

                          <li>
                            <Link
                              aria-label="link"
                              className="dropdown-text"
                              to="/financial-services-automation/"
                            >
                              Finance
                            </Link>
                          </li>

                          <li>
                            <Link
                              aria-label="link"
                              className="dropdown-text"
                              to="/patient-care-automation/"
                            >
                              Healthcare
                            </Link>
                          </li>
                          <li>
                            {" "}
                            <Link
                              aria-label="link"
                              className="dropdown-text"
                              to="/retail-automation/"
                            >
                              Retail
                            </Link>
                          </li>

                          <li>
                            <Link
                              aria-label="link"
                              className="dropdown-text"
                              to="/transportation-and-logistics/"
                            >
                              Transportation
                            </Link>
                          </li>
                        </ul>
                      </div> */}

                        {/* <div className="item-casestudy pl-3">
                        <div className="row">
                          <div className="p-relative">
                            <div className="case-study-card">
                              {caseStudyItem?.node?.mainImage && (
                                <GatsbyImage
                                  image={
                                    caseStudyItem?.node?.mainImage !== null
                                      ? caseStudyItem?.node?.mainImage.asset
                                          .gatsbyImageData
                                      : null
                                  }
                                  className="d-flex w-100 img-fluid"
                                  alt={caseStudyItem?.node?.title}
                                  style={{ height: "13rem" }}
                                  layout="constrained"
                                  placeholder="blurred"
                                />
                              )}

                              <div className="card-body">
                                {caseStudyItem?.node?.title && (
                                  <small className="text-white font-bold">
                                    {caseStudyItem?.node?.title}
                                  </small>
                                )}

                                <div className="card-link">
                                  {caseStudyItem?.node?.slug?.current && (
                                    <Link
                                      aria-label="link"
                                      to={`/case-study/${caseStudyItem?.node?.slug?.current}`}
                                    >
                                      Read Case Study
                                    </Link>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      </div>
                    </div>
                  </li>
                  <li className="dropdown">
                    <a href="#" className="nav-text d-flex align-items-center">
                      Partners <i className="fa fa-caret-down ml-1"></i>
                    </a>
                    <div className="mega-menu mega-menu-center">
                      <div className="container">
                        <div className="item">
                          <ul>
                            <li>
                              <Link aria-label="link" to="/partners/quickbase/">
                                Quickbase
                              </Link>
                            </li>
                            <li>
                              <Link aria-label="link" to="/partners/workato/">
                                Workato
                              </Link>
                            </li>
                            <li>
                              <Link aria-label="link" to="/partners/pipefy/">
                                Pipefy
                              </Link>
                            </li>
                            <li>
                              <a aria-label="link" href="/partners/trackvia/">
                                TrackVia
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </nav>

              <Link
                aria-label="link"
                to="/case-studies/"
                className="nav-link"
                style={{ whiteSpace: "nowrap", wordBreak: "keep-all" }}
              >
                case studies
              </Link>
              <nav>
                <ul className="">
                  <li className="dropdown">
                    <a href="#" className="nav-text d-flex align-items-center">
                      Resources <i className="fa fa-caret-down ml-1"></i>
                    </a>
                    <div className="mega-menu mega-menu-resources">
                      <div className="container">
                        <div className="item">
                          <ul>
                            <li>
                              <Link aria-label="link" to="/knowledge-base/">
                                knowledge base
                              </Link>
                            </li>
                            <li>
                              <Link aria-label="link" to="/blog/">
                                Blog
                              </Link>
                            </li>
                            <li>
                              <Link aria-label="link" to="/faq/">
                                faq
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="dropdown">
                    <a href="#" className="nav-text d-flex align-items-center">
                      About <i className="fa fa-caret-down ml-1"></i>
                    </a>
                    <div className="mega-menu mega-menu-center">
                      <div className="container">
                        <div className="item">
                          <ul>
                            <li>
                              <Link aria-label="link" to="/about-us/">
                                about us
                              </Link>
                            </li>
                            <li>
                              <Link aria-label="link" to="/our-approach/">
                                our approach
                              </Link>
                            </li>
                            <li>
                              <Link aria-label="link" to="/careers/">
                                careers
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </nav>
            </div>
            <Link
              aria-label="link"
              id="nav-contact-link-search"
              to="/contact/"
              style={{
                whiteSpace: "nowrap",
                wordBreak: "keep-all",
                marginLeft: "1rem",
              }}
            >
              Let's Chat
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default HorizontalMenu
